/* General Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f5f7;
    color: #333;
    line-height: 1.6;
  }
  
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  /* Headings */
  h1, h2, h3 {
    color: #333;
    font-weight: 700;
  }
  
  h1 {
    font-size: 2rem;
    color: #222;
    text-align: center;
  }
  
  /* Dashboard Styles */
  .dashboard {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }
  
  /* Grid Layout */
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }
  
  @media (min-width: 992px) {
    .grid {
      grid-template-columns: repeat(3, 1fr); /* Forces three columns on larger screens */
    }
  }
  
  
  /* Chart Cards */
  .chart-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    padding: 1.5rem;
    transition: transform 0.2s ease;
    min-width: 400px;
  }

  @media (max-width: 760px) {
    .chart-card {
        width: 100%; /* Use full width for better responsiveness */
        margin: 10px 0; /* Add some margin between cards */
    }
}
  
  .chart-card:hover {
    transform: translateY(-5px);
  }
  
  .chart-card h2 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    color: #555;
  }
  
  .chart-card .recharts-wrapper {
    width: 100%;
    height: 100%;
  }
  
  /* Accuracy Comparison Cards */
  .accuracy-comparison {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .accuracy-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1.5rem;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .accuracy-card:hover {
    transform: translateY(-5px);
  }
  
  .accuracy-card ul {
    list-style: none;
    padding: 0;
  }
  
  .accuracy-card li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    font-size: 1rem;
    color: #666;
  }
  
  .accuracy-card li span {
    font-weight: 600;
  }
  
  .accuracy-card .icon {
    font-size: 1.25rem;
  }
  
  .accuracy-card .arrow-up {
    color: #4caf50;
  }
  
  .accuracy-card .arrow-down {
    color: #f44336;
  }
  
  /* User Information */
  .user-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }
  
  .user-info img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  
  .user-info h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
  }
  
  .user-info p {
    color: #777;
    font-size: 0.95rem;
  }
  
  /* Percentile Prediction */
  .percentile-prediction {
    margin-top: 2rem;
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  }
  
  .percentile-prediction p {
    font-size: 2.25rem;
    font-weight: 700;
    color: #4caf50;
  }
  
  .percentile-prediction .percentile-text {
    font-size: 1.15rem;
    color: #888;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .dashboard h1 {
      font-size: 1.75rem;
    }
  
    .grid {
      grid-template-columns: 1fr;
    }
  
    .chart-card {
      padding: 1rem;
    }
  
    .accuracy-comparison {
      flex-direction: column;
    }
  
    .accuracy-card {
      max-width: 100%;
    }
  
    .user-info {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .user-info img {
      margin-bottom: 1rem;
    }
  
    .percentile-prediction p {
      font-size: 2rem;
    }
  }
  