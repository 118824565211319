@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
html,body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.custom-button { 
	display:inline-block;
	padding:0.3em 1.2em;
	margin:0 0.1em 0.1em 0;
	border:0.16em solid rgba(255,255,255,0);
	border-radius:2em;
	box-sizing: border-box;
	text-decoration:none;
	font-family:'Roboto',sans-serif;
	font-weight:300;
	color:#FFFFFF;
	text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
	text-align:center;
	transition: all 0.2s;	
}

a.custom-button:hover { 
	border-color: rgba(255,255,255,1);
}

@media all and (max-width:30em){
 a.custom-button{
	display:block;
	margin:0.2em auto;
	}
}

.button7{
	display:inline-block;
    padding:0.7em 1.7em;
    margin:0 0.3em 0.3em 0;
    border-radius:0.2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:400;
    color:#FFFFFF;
    background-color:#3369ff;
    box-shadow:inset 0 -0.6em 1em -0.35em rgba(0,0,0,0.17),inset 0 0.6em 2em -0.3em rgba(255,255,255,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
    text-align:center;
    position:relative;
}
.button7:active{
    box-shadow:inset 0 0.6em 2em -0.3em rgba(0,0,0,0.15),inset 0 0 0em 0.05em rgba(255,255,255,0.12);
}
@media all and (max-width:30em){
    .button7{
        display:block;
        margin:0.4em auto;
    }
}

.bouncy{
	animation:bouncy 5s infinite linear;
	position:relative;
}
@keyframes bouncy {
	0%{top:0em}
	40%{top:0em}
	43%{top:-0.9em}
	46%{top:0em}
	48%{top:-0.4em}
	50%{top:0em}
	100%{top:0em;}
}

.social
{
	text-align: center;
	background-color: black;
}

.Signup-container{
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	

}

.Signup{

	width:400px;
	height:400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	
	border: #888888 solid 1px;
	background-color: #f4f4f4;

}

@media(min-width:700px){
    .main-text{
      font-size: 5em;
    }
}

@media(max-width:700px){
    .main-text{
      font-size: 3em;
    }
}

.main-text{
	font-size: 10em;
}

.glow-on-hover {
    width: 220px;
    height: 40px;
    border: none;
    outline: none;
    color: black;
    background: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, transparent, transparent, transparent, #fff, #fff, #fff);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    border-radius: 5px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}


.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  background: rgba(0,0,0,.5);
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top:0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #90caf9;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: flex;
  padding: 10px 20px;
  color: #f44336;
  font-size: 20px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px
}

.login-box a:hover {
  background: #f44336;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #e57373,
              0 0 25px #ef5350,
              0 0 50px #f44336,
              0 0 100px #e53935;
              font-size: 20px;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #f44336);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #f44336);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #f44336);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #f44336);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,100% {
    bottom: 100%;
  }
}

string {
  display: flex;
  flex-direction: column;
  text-align: center;
  animation: move 4s infinite;
}

.greeting {
  position: relative;
  top: 8.6vmin;
  animation: white-out 5s infinite;
}

.closure::after {
  content: '';
  position: absolute;
  height: 25vmin;
  width: 40vmin;
  background: #282c34;
  transform: translate(-45vmin, -24.5vmin);
}

.closure::before {
  content: '';
  position: absolute;
  height: 25vmin;
  width: 40vmin;
  background: #282c34;
  transform: translate(-40vmin, 5vmin);
}

.en {
  color: #fa8231;
}

.es {
  color: white;
}

.de {
  color: #c678dd;
}

.it {
  color: #a9b0bd;
}

@keyframes move {
  25% {
    transform: translatey(-5.8vmin);
    opacity: 1;
  }
  50% {
    transform: translatey(-11vmin);
  }
  75% {
    transform: translatey(-16.5vmin);
  }
}

.main_heading {
  color:"white";
  margin-top:0+"%";
  text-transform: uppercase;
  font-family: verdana;
  font-size: 10em;
  font-weight: 700;
  color: #f5f5f5;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}


/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  display: inline-flex;
  grid-gap: 10px;
  gap: 10px;
}
.loader:before,
.loader:after {
  content: "";
  height: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    linear-gradient(#001F3F 0 0) top/100% 40% no-repeat,
    radial-gradient(farthest-side,#000 95%,#0000) 50%/8px 8px no-repeat
    #fff;
  animation: l7 1.5s infinite alternate ease-in;
}
@keyframes l7 {
  0%,
  70% {background-size:100% 40%,8px 8px}
  85% {background-size:100% 120%,8px 8px}
  100% {background-size:100% 40%,8px 8px}
}

.responsive-component-even {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  margin-top: 40px;
  justify-content: center;
  width: 60%;
  height:400px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    width: 100vw;
    height:100%;
    align-items: center;
  }
}

.responsive-component-odd {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  justify-content: center;
  width: 60%;
  margin-bottom: 40px;
  margin-top: 40px;
  height:400px;
  
  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    width: 100vw;
    height:100%;
    align-items: center;
  }
}


.image-container-even {
  flex: 1 1;
  margin-right: 0px;
  box-shadow: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 2%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.image-container-odd {
  flex: 1 1;
  margin-right: 0px;
  box-shadow: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 2%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.responsive-image {
  max-width: 50%;
  height: auto;
  margin-right: 50%;
  @media (max-width: 768px) {
    max-width: 60%;
    margin-left: 20%; /* Adjust this value as needed */
  }
}

.responsive-image-odd {
  max-width: 50%;
  height: auto;
  margin-left: 50%;
  @media (max-width: 768px) {
    max-width: 60%;
    margin-left: 20%; /* Adjust this value as needed */
  }
}

.content-container {
  flex: 1 1;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #7f388b;

  @media (max-width: 768px) {
    font-size: 1.2em;
    text-align: center;
    font-family:'Times New Roman', Times, serif;
    font-weight: bold;

  }
}

.description {
  font-size: 1.55rem;
  line-height: 2;

  @media (max-width: 768px) {
    font-size: 1em;
    line-height: 1.3;
    padding: 10px;
    padding-left: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.animated-feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 100px 0;
  overflow: hidden;
}

.animated-feature.reverse {
  flex-direction: row-reverse;
}

.animated-feature .image-container,
.animated-feature .content-container {
  flex: 1 1;
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.animated-feature.reverse .image-container,
.animated-feature.reverse .content-container {
  transform: translateX(50px);
}

.animated-feature.in-view .image-container,
.animated-feature.in-view .content-container {
  opacity: 1;
  transform: translateX(0);
}

.animated-feature .responsive-image {
  max-width: 100%;
  height: auto;
}

.animated-feature .title {
  font-size: 36px;
  margin-bottom: 20px;
}

.animated-feature .description {
  font-size: 18px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .animated-feature {
    flex-direction: column;
    padding: 50px 0;
  }

  .animated-feature.reverse {
    flex-direction: column;
  }

  .animated-feature .image-container,
  .animated-feature .content-container {
    width: 100%;
    margin-bottom: 30px;
  }
}

.quora-activity {
  max-width: 800px;
  margin: 0 auto;
  padding: 24px;
  font-family: Arial, sans-serif;
  width: 700px;

}

.quora-title {
  color: #7f388b;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 24px;
}

.question-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 24px;
}

.question-input textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-size: 16px;
}

.anonymous-switch {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.anonymous-switch label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
}

.anonymous-switch input[type="checkbox"] {
  margin-right: 8px;
}

.post-button {
  background-color: #7f388b;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 16px;
  width: 100%;
}

.post-button:hover {
  background-color: #662e70;
}

.post-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.question-list {
  list-style-type: none;
  padding: 0;
}

.question-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 16px;
}

.question-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
  text-align: left;
}

.question-author, .question-views, .question-date {
  font-size: 14px;
  color: #666;
  margin-bottom: 4px;
}

.view-icon {
  margin-right: 4px;
}

.answer-section {
  margin-top: 16px;
  border-top: 1px solid #eee;
  padding-top: 16px;
}

.answerer-info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
}

.answerer-name {
  font-weight: bold;
  color: #333;
}

.audio-player {
  display: flex;
  align-items: center;
  background-color: #f2e9f5;
  border-radius: 24px;
  padding: 8px 16px;
  margin-bottom: 16px;
}

.play-pause-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 12px;
}

.audio-slider {
  flex-grow: 1;
  margin: 0 12px;
}

.audio-time {
  font-size: 12px;
  color: #666;
}

.upvote-button {
  background-color: #f2e9f5;
  color: #7f388b;
  border: 1px solid #7f388b;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}

.upvote-button:hover {
  background-color: #e6d3eb;
}

.upvote-button.upvoted {
  background-color: #7f388b;
  color: white;
}

.upvote-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loading-text {
  text-align: center;
  color: #666;
}

.load-more-button {
  background-color: #f2e9f5;
  color: #7f388b;
  border: 1px solid #7f388b;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
}

.load-more-button:hover {
  background-color: #e6d3eb;
}

.study-buddies-screen {
  flex: 1 1;
  background-color: #f2ebf3;
  font-family: Arial, sans-serif;
}

.content-container {
  flex-grow: 1;
  background-color: #fff;
  width:600px;
  padding: 8px;
  box-sizing: border-box;
  min-height: 100vh;
}

.toolbar {
  background-color: #7f388b;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100px;
  box-sizing: border-box;
}

.toolbar-text {
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.random-users-container {
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.random-user-card {
  width: 150px;
  height: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.random-user-image {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-bottom: 10px;
}

.random-user-name {
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.tag {
  padding: 4px 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
  width: 100px;
  text-align: center;
}

.react-button {
  margin-top: 5px;
  background-color: #7f388b;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.react-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin: 8px;
  color: #7f388b;
}

.table-container {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #e0e0e0;
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

td {
  padding: 16px;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  margin: 8px;
}

.name-cell {
  text-align: left;
  margin: 5px;
}

.buddy-image {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.no-buddies-text {
  text-align: center;
  padding: 16px;
  color: #666;
}

.next-page-button {
  background-color: #7f388b;
  padding: 10px 20px;
  border-radius: 20px;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  margin: 20px auto;
}

.loading-text {
  text-align: center;
  padding: 10px;
  color: #666;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #7f388b;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .random-users-container {
    justify-content: flex-start;
    overflow-x: auto;
    flex-wrap: nowrap;
  }

  .random-user-card {
    flex-shrink: 0;
  }

  .table-container {
    overflow-x: auto;
  }
}
@media(min-width:700px){
    .first-feature{
        display: flex;
        height: 300px;
        width: 100%;
    }
}


.first-feature-pic{
    flex:2 1;
    
 
}
.first-feature-text h1{
    text-align: center;
vertical-align: middle;
display: flex; 
flex-direction: column;
padding:0.2em;
color: black;
text-transform: uppercase;
font-family: Arial, Helvetica, sans-serif;
-webkit-text-decoration: black;
        text-decoration: black;
}
.first-feature-text{
    flex:3 1;
  
    text-align: center;
vertical-align: middle;
display: flex;
flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding:2em;
  font-weight: bold;
  font-size: larger;
   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

  
  @media only screen and (max-width: 600px) {
    .Card_card__y67jy {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        width: auto;
        height: 400px;
        margin-bottom: 20px;
    border-radius: 16px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(3.2px);
    -webkit-backdrop-filter: blur(3.2px);
    border: 0px solid rgba(255, 255, 255, 0.3);
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 0 2rem 2rem 2rem;
        border-radius: 10px;
        border-width: 0px;
        -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
      }
  } 


  @media only screen and (min-width: 601px) {
    .Card_card__y67jy {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        width: "auto";
        height: 700px;
    border-radius: 16px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    backdrop-filter: blur(3.2px);
    -webkit-backdrop-filter: blur(3.2px);
    border: 0px solid rgba(255, 255, 255, 0.3);
        padding: 0 2rem 2rem 2rem;
        border-radius: 10px;
        -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
        border-width: 0px;
      }
  } 

  @media only screen and (max-width: 600px) {
    .Card_card__y67jy img {
        margin-top: 3%;
        width: 250px;
        padding:5px;
        border-radius: 10px;
        background-color: transparent;
        object-fit: fill;
        height: 500px;

      }
  } 
  
  @media only screen and (min-width: 601px) {
    .Card_card__y67jy img {
        margin-top: 5%;
        width: auto;
        padding:5px;
        border-radius: 10px;
        background-color: transparent;
        object-fit: fill;
        height: 500px;
      }
  } 


  @media only screen and (max-width: 600px) {
    .Card_card__y67jy h2 {
        margin: 0;
        margin-top: 1rem;
        color:black;
        font-weight: bold;
        margin-bottom: 0px;
        width: 100%;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        width: 300px;
        text-align: center;
        font-family:'Times New Roman', Times, serif
      }
  } 
  

  @media only screen and (min-width: 601px) {
    .Card_card__y67jy h2 {
        margin: 0;
        margin-top: 1rem;
        color:black;
        margin-bottom: 0px;
        width: 100%;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        text-align: center;
        width: 290px;
        font-family:'Times New Roman', Times, serif
      }
  } 
  
  
  @media only screen and (max-width: 600px) {
    .Card_card__y67jy p {
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0rem;
        color: black;
        font-size: 12px;
        width: 300px;
        text-align: center;
        font-family: 'Times New Roman', Times, serif;
      }
      
  } 
  
  @media only screen and (min-width: 601px) {
    .Card_card__y67jy p {
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 0rem;
        color: black;
        width: 250px;
        text-align: center;
        font-family: 'Times New Roman', Times, serif;
      }
      
  } 
  
 
  .Card_card__y67jy .Card_btnn__27I7J {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
.btn {
    background-color: #0094ff;
    color: #fff;
    border: none;
    outline: none;
    font-size: 1.2rem;
    border-radius: 10px;
    padding: 11px 1rem;
    /* margin: 0 0.5rem ; */
    width: 7.5rem;
  }
  
.App {
  text-align: center;
  margin:0;
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Landing {
  width: 100%; /* Full viewport height */
}
.menu-icon {
  display: none; /* Hide the menu icon by default */
}

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 110%;
  background-color: #ffffff; /* White background */
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: right 0.3s ease;
  z-index: 999; /* Ensure navbar is on top */
}

.navbar.open {
  right: 0;
}

.nav-links {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.nav-button,
.contact-button {
  background-color: transparent;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.nav-button:hover,
.contact-button:hover {
  background-color: #f0f0f0;
}

.nav-logo {
  font-size: 24px;
}

.content {
  margin-top: 60px; /* Adjust according to navbar height */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Media query for small screens (up to 600px) */

@media screen and (max-width: 767px) {
  .menu-icon {
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1000;
    cursor: pointer;
  }
  
  .menu-icon .menu-line {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
    transition: transform 0.3s ease;
  }
  
  .menu-icon.open .menu-line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .menu-icon.open .menu-line:nth-child(2) {
    opacity: 0;
  }
  
  .menu-icon.open .menu-line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  .navbar {
    display: none; /* Hide the navbar on mobile screens by default */
    flex-direction: column;
    align-items: center;
  }

  .navbar.open {
    display: flex; /* Show the navbar when the menu is open */
  }

  .nav-links {
    flex-direction: column; /* Stack navigation links vertically on mobile screens */
  }

  .nav-logo {
    order: -1; /* Move the logo to the beginning */
    margin-bottom: 10px; /* Add margin to separate from the links */
  }
}

/* App.css */

.article-section {
  padding: 20px;
}

.article-section h2 {
  text-align: center;
}

.articles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px;
}

.article {
  width: 300px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin:5px;
}

.article img {
  width: 100%;
  height: 220px;
  border-radius: 5px;
}

.article-info {
  margin-top: 10px;
}

.article-info h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.article-info p {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.article-info a {
  display: inline-block;
  color: blue;
  text-decoration: none;
  font-weight: bold;
}

.article-info a:hover {
  text-decoration: underline;
}

/* App.css */

.contact-form {
  width: 95%;
  max-width: 600px; /* Set maximum width */
  margin: 0 auto; /* Center the form horizontally */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-group textarea {
  resize: vertical;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Elevation */
  padding: 20px;
}

.icon {
  font-size: 48px;
}

.text {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}


.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.json-input {
  margin: 10px 0;
}

.json-submit {
  margin: 10px 0;
}

.test-container {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
}

.passage {
  font-weight: bold;
  margin-bottom: 20px;
}

.options {
  list-style-type: none;
  padding-left: 0;
}

.options li {
  margin-bottom: 10px;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.correct-answer {
  color: green;
}



.feedback {
  margin-top: 10px;
}

.explanation {
  color: blue;
}

.tip {
  color: red;
}

.explanation-text,
.tip-text {
  font-style: italic;
}

.word-count {
  margin-bottom: 10px;
}

.category {
  font-weight: bold;
  margin-top: 5px;
  color: orange;
}

.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 200px; /* Adjust according to your logo size */
}

.privacy-policy-content {
  text-align: justify;
}

.privacy-policy-content h1 {
  margin-bottom: 10px;
}

.privacy-policy-content p {
  margin-bottom: 20px;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  padding:0;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.column {
  padding: 20px;
  box-sizing: border-box;
}

.column-8 {
  width: 75%; /* 8/12 ratio */
  background-color: #fff;
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: white; /* Optional: Just to distinguish the columns */
}

.column-4 {
  width: 25%; /* 4/12 ratio */
  background-color: #fff; 
  overflow-y: auto;
  scrollbar-width: none;/* Optional: Just to distinguish the columns */
}

.app-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 16px;
  margin-top: 0;
  overflow-y: auto;
  grid-gap: auto;
  gap: auto;
  width: 100vw;
}

.column {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.left-column {
  flex: 0.5 1;
  min-width: 150px;
  width: 150px;
}

.center-column {
  flex: 2 1;
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 0; /* Allows center column to shrink below min-content */
}

.right-column {
  flex: 1 1;
  min-width: 300px;
}

.dashboard-button {
  width: 90%;
  max-width: 600px;
  margin-top: 10px;
  background-color: #7f388b;
  color: white;
  font-weight: bold;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dashboard-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.tabs-container {
  width: 100%;
  overflow-x: auto;
  margin: 0px 0;
}

.tabs {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.tab {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
}

.tab.active {
  border-bottom-color: #7f388b;
  color: #7f388b;
}

.tab-content {
  width: 100%;
}

.shimmer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.timer-card {
  padding: 20px;
  margin-top: 20px;
}

.countdown-text {
  margin-top: 10px;
  font-weight: bold;
}

.lottie {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .app-container {
    padding: 15px;
    grid-gap: auto;
    gap: auto;
  }
  
  .left-column,
  .right-column {
    min-width: 200px;
  }
}

@media screen and (max-width: 800px) {
  .app-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .column {
    width: 100%;
  }
  
  .left-column,
  .right-column {
    min-width: 100%;
  }
  
  .dashboard-button {
    width: 100%;
  }
  
  .tabs {
    justify-content: flex-start;
    padding: 0 10px;
  }
}

@media screen and (max-width: 480px) {
  .app-container {
    padding: 10px;
  }
  
  .tabs {
    grid-gap: 5px;
    gap: 5px;
  }
  
  .tab {
    padding: 8px 12px;
    font-size: 14px;
  }
  
  .countdown-text {
    font-size: 14px;
  }
}
/* Login.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-container {
    background-color: white;
    padding: 4rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .logo {
    max-width: 200px;
    height: auto;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-label {
    font-weight: 600;
  }
  
  .form-control {
    border-radius: 4px;
  }
  
  .submit-button {
    width: 100%;
    margin-top: 1rem;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    background-color: #7f388b;
  }
  
  .alert {
    margin-bottom: 1rem;
  }
  
  .spinner-border {
    margin-right: 0.5rem;
  }
.profile-card {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .profile-card:hover {
    transform: translateY(-5px);
  }
  
  .profile-image img {
    width: 100%;
    height: auto;
  }
  
  .profile-info {
    padding: 15px;
    text-align: center;
  }
  
  .profile-info h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }
  
  .profile-info h4 {
    margin: 5px 0;
    font-size: 1.1em;
    color: #777;
  }
  
  .profile-info p {
    margin: 10px 0 0;
    color: #555;
  }
  
/* Base styles */
.max-w-4xl {
    max-width: 56rem;
  }
  
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .p-4 {
    padding: 1rem;
  }
  
  .space-y-12 > * + * {
    margin-top: 3rem;
  }
  
  /* Hero Section */
  .hero-section {
    position: relative;
    background: linear-gradient(to right, #2563eb, #9333ea);
    border-radius: 1rem;
    padding: 2rem;
    color: white;
  }
  
  .hero-overlay {
    position: absolute;
    inset: 0;
    background-color: black;
    opacity: 0.1;
    border-radius: 1rem;
  }
  
  .hero-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  @media (min-width: 768px) {
    .hero-content {
      flex-direction: row;
    }
  }
  
  /* Profile Image */
  .profile-image-container {
    position: relative;
  }
  
  .profile-image {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    border: 4px solid white;
    overflow: hidden;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .online-badge {
    position: absolute;
    bottom: -0.5rem;
    right: 0;
    background-color: #22c55e;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
  }
  
  /* Profile Info */
  .profile-info {
    flex: 1 1;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .profile-info {
      text-align: left;
    }
  }
  
  .badge-container {
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .badge {
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
  }
  
  .badge-yellow {
    background-color: #eab308;
  }
  
  .badge-blue {
    background-color: #1d4ed8;
  }
  
  .badge-outline {
    border: 1px solid white;
    color: white;
  }
  
  .profile-name {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .profile-title {
    font-size: 1.25rem;
    opacity: 0.9;
    margin-bottom: 0.75rem;
  }
  
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 768px) {
    .skills-container {
      justify-content: flex-start;
    }
  }
  
  /* Rating Section */
  .rating-section {
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .rating-section {
      text-align: right;
    }
  }
  
  .rating-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: 768px) {
    .rating-stars {
      justify-content: flex-end;
    }
  }
  
  .star-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #facc15;
  }
  
  .rating-value {
    font-weight: bold;
    font-size: 1.125rem;
  }
  
  .rating-count {
    opacity: 0.9;
  }
  
  /* Pricing Section */
  .pricing {
    text-align: center;
  }
  
  .pricing-label {
    font-size: 0.875rem;
    opacity: 0.9;
  }
  
  .price {
    font-size: 1.875rem;
    font-weight: bold;
  }
  
  .book-button {
    width: 100%;
    background-color: white;
    color: #2563eb;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .book-button:hover {
    background-color: #f3f4f6;
  }
  
  /* Value Proposition Banner */
  .value-prop-banner {
    background: linear-gradient(to right, #f3e8ff, #eff6ff);
    border: 2px solid #dbeafe;
    border-radius: 0.5rem;
    padding: 1.5rem;
  }
  
  .banner-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .banner-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1e40af;
    margin-bottom: 0.5rem;
  }
  
  .banner-subtitle {
    color: #4b5563;
  }
  
  .packages-grid {
    display: grid;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .packages-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .package-card {
    background-color: white;
    border-radius: 0.75rem;
    padding: 1.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .package-header {
    display: flex;
    align-items: center;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .package-icon-container {
    padding: 0.5rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
  }
  
  .package-icon {
    width: 2rem;
    height: 2rem;
  }
  
  .package-title {
    font-weight: 600;
    font-size: 1.125rem;
  }
  
  .feature-list {
    list-style: none;
    padding: 0;
    margin: 0;
    space-between: 0.75rem;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 0.75rem;
  }
  
  .check-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #22c55e;
  }
  
  /* Platform Features */
  .platform-features {
    margin-top: 3rem;
  }
  
  .features-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .features-title {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .features-subtitle {
    color: #4b5563;
  }
  
  .features-grid {
    display: grid;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .features-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .feature-card {
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    transition: box-shadow 0.3s;
  }
  
  .feature-card:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
  
  .feature-content {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .feature-icon-container {
    padding: 0.75rem;
    border-radius: 0.75rem;
    background-color: #f9fafb;
    transition: background-color 0.3s;
  }
  
  .feature-card:hover .feature-icon-container {
    background-color: #eff6ff;
  }
  
  /* Stats Section */
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .stats-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .stat-card {
    text-align: center;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    transition: box-shadow 0.2s;
  }
  
  .stat-card:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .stat-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .stat-icon-wrapper {
    padding: 0.5rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
  }
  
  .stat-value {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }
  
  .stat-label {
    color: #374151;
    font-weight: 500;
  }
  
  .stat-subtitle {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  /* App Download Section */
  .app-download {
    background: linear-gradient(to right, #2563eb, #9333ea);
    color: white;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
  }
  
  .download-content {
    max-width: 42rem;
    margin: 0 auto;
  }
  
  .download-title {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .download-description {
    font-size: 1.125rem;
    opacity: 0.9;
    margin-bottom: 2rem;
  }
  
  .download-buttons {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
  }
  
  @media (min-width: 768px) {
    .download-buttons {
      flex-direction: row;
    }
  }
  
  .download-button {
    background-color: white;
    color: #2563eb;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    transition: background-color 0.2s;
  }
  
  .download-button:hover {
    background-color: #f3f4f6;
  }
  
  .download-footer {
    margin-top: 1.5rem;
    font-size: 0.875rem;
    opacity: 0.75;
  }
  
  /* Utility Classes */
  .text-center {
    text-align: center;
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
  }
  
  .text-2xl {
    font-size: 1.5rem;
  }
  
  .text-3xl {
    font-size: 1.875rem;
  }
  
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  
  .mb-8 {
    margin-bottom: 2rem;
  }
/* General Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f4f5f7;
    color: #333;
    line-height: 1.6;
  }
  
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  /* Headings */
  h1, h2, h3 {
    color: #333;
    font-weight: 700;
  }
  
  h1 {
    font-size: 2rem;
    color: #222;
    text-align: center;
  }
  
  /* Dashboard Styles */
  .dashboard {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }
  
  /* Grid Layout */
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  
  @media (min-width: 992px) {
    .grid {
      grid-template-columns: repeat(3, 1fr); /* Forces three columns on larger screens */
    }
  }
  
  
  /* Chart Cards */
  .chart-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
    padding: 1.5rem;
    transition: transform 0.2s ease;
    min-width: 400px;
  }

  @media (max-width: 760px) {
    .chart-card {
        width: 100%; /* Use full width for better responsiveness */
        margin: 10px 0; /* Add some margin between cards */
    }
}
  
  .chart-card:hover {
    transform: translateY(-5px);
  }
  
  .chart-card h2 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    color: #555;
  }
  
  .chart-card .recharts-wrapper {
    width: 100%;
    height: 100%;
  }
  
  /* Accuracy Comparison Cards */
  .accuracy-comparison {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .accuracy-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1.5rem;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .accuracy-card:hover {
    transform: translateY(-5px);
  }
  
  .accuracy-card ul {
    list-style: none;
    padding: 0;
  }
  
  .accuracy-card li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
    font-size: 1rem;
    color: #666;
  }
  
  .accuracy-card li span {
    font-weight: 600;
  }
  
  .accuracy-card .icon {
    font-size: 1.25rem;
  }
  
  .accuracy-card .arrow-up {
    color: #4caf50;
  }
  
  .accuracy-card .arrow-down {
    color: #f44336;
  }
  
  /* User Information */
  .user-info {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 2rem;
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }
  
  .user-info img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  
  .user-info h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
  }
  
  .user-info p {
    color: #777;
    font-size: 0.95rem;
  }
  
  /* Percentile Prediction */
  .percentile-prediction {
    margin-top: 2rem;
    text-align: center;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  }
  
  .percentile-prediction p {
    font-size: 2.25rem;
    font-weight: 700;
    color: #4caf50;
  }
  
  .percentile-prediction .percentile-text {
    font-size: 1.15rem;
    color: #888;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .dashboard h1 {
      font-size: 1.75rem;
    }
  
    .grid {
      grid-template-columns: 1fr;
    }
  
    .chart-card {
      padding: 1rem;
    }
  
    .accuracy-comparison {
      flex-direction: column;
    }
  
    .accuracy-card {
      max-width: 100%;
    }
  
    .user-info {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .user-info img {
      margin-bottom: 1rem;
    }
  
    .percentile-prediction p {
      font-size: 2rem;
    }
  }
  

.blog-post-editor {
    max-width: 800px;
    margin: 0 auto;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
  }
  
  .blog-post-editor input[type="text"] {
    width: 720px;
    padding: 12px;
    font-size: 1.25rem;
    margin-bottom: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    transition: border-color 0.2s ease-in-out;
  }
  
  .blog-post-editor input[type="text"]:focus {
    border-color: #3f51b5;
    outline: none;

  }
  
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    background-color: #fafafa;
    border-radius: 10px;
  }
  
  .toolbar button {
    padding: 6px 10px;
    margin-right: 8px;
    font-size: 1.1rem;
    color: #3f51b5;
    border: 1px solid #3f51b5;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  
  .toolbar button:hover {
    background-color: #3f51b5;
    color: #ffffff;
  }
  
  .toolbar .camera-icon {
    color: #757575;
    cursor: pointer;
  }
  
  .toolbar .camera-icon:hover {
    color: #3f51b5;
  }
  
  .editor-container {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 12px;
    min-height: 500px;
    margin-bottom: 16px;
    background-color: #fff;
    margin-top: 10px;
    width: 100%;
  }
  
  .editor-container .public-DraftEditorPlaceholder-root {
    color: #bdbdbd;
    width: 100%;
  }
  
  .editor-container .public-DraftEditor-content {
    font-size: 1.5rem;
    width: 100%;
  }
  
  .save-button {
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #7f388b;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .save-button:hover {
    background-color: #7f388b;
  }
  
/* Reset and Base Styles */
*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Georgia', serif;
    background-color: #fafafa;
    color: #222;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    font-size: 18px;
}

/* Main Container */
article {
    max-width: 100vw;
    margin: 0 auto;
    padding: 40px 20px;
    background: #fff;
    
}

/* Header Styling */
header {
    text-align: center;
    margin-bottom: 2rem;
    padding: 16px;
}

h1 {
    font-size: 3rem;
    line-height: 1.2;
    color: #111;
    font-weight: 400;
    margin-bottom: 1.2rem;
}

.subtitle {
    font-size: 1.25rem;
    color: #555;
    max-width: 600px;
    margin: 0 auto 1.5rem;
    text-align: center;
    line-height: 1.6;
}

/* Author Info */
.author-info {
    margin: 1.5rem 0;
    font-size: 1rem;
    color: #888;
}

.author-info p {
    margin: 0.2rem 0;
}

/* Content Styling */
.prose {
    font-size: 1.25rem;
    line-height: 1.8;
    color: #333;
}

.prose p {
    margin-bottom: 1.5rem;
    font-size: 2rem;
}

.heading-one {
    font-size: 2.5rem;
    font-weight: 400;
    margin: 2rem 0 1rem;
}

.heading-two {
    font-size: 2rem;
    font-weight: 400;
    margin: 1.5rem 0 1rem;
}

.heading-three {
    font-size: 1.75rem;
    font-weight: 400;
    margin: 1.5rem 0 1rem;
}

/* Featured Image */
.featured-image {
    width: 100%;
    height: auto;
    margin: 2rem 0;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Tags */
.tags {
    margin: 1rem 0;
}

.tags span {
    display: inline-block;
    margin-right: 0.5rem;
    font-size: 0.9rem;
    color: #666;
    font-weight: 400;
}

.tags span:before {
    content: '#';
    color: #999;
}

/* Footer */
footer {
    margin-top: 3rem;
    padding-top: 1rem;
    border-top: 1px solid #eaeaea;
    font-size: 0.9rem;
    color: #666;
}

/* Links */
a {
    color: #0056b3;
    text-decoration: none;
    transition: color 0.3s;
}

a:hover {
    color: #004494;
}

/* Image Caption */
img + em {
    display: block;
    text-align: right;
    font-size: 0.9rem;
    color: #999;
    margin-top: -1rem;
    margin-bottom: 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    h1 {
        font-size: 2.5rem;
    }
    
    .prose {
        font-size: 1.1rem;
    }
    
    article {
        padding: 0 15px;
    }
}

