
.blog-post-editor {
    max-width: 800px;
    margin: 0 auto;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
  }
  
  .blog-post-editor input[type="text"] {
    width: 720px;
    padding: 12px;
    font-size: 1.25rem;
    margin-bottom: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    transition: border-color 0.2s ease-in-out;
  }
  
  .blog-post-editor input[type="text"]:focus {
    border-color: #3f51b5;
    outline: none;

  }
  
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    background-color: #fafafa;
    border-radius: 10px;
  }
  
  .toolbar button {
    padding: 6px 10px;
    margin-right: 8px;
    font-size: 1.1rem;
    color: #3f51b5;
    border: 1px solid #3f51b5;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  
  .toolbar button:hover {
    background-color: #3f51b5;
    color: #ffffff;
  }
  
  .toolbar .camera-icon {
    color: #757575;
    cursor: pointer;
  }
  
  .toolbar .camera-icon:hover {
    color: #3f51b5;
  }
  
  .editor-container {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 12px;
    min-height: 500px;
    margin-bottom: 16px;
    background-color: #fff;
    margin-top: 10px;
    width: 100%;
  }
  
  .editor-container .public-DraftEditorPlaceholder-root {
    color: #bdbdbd;
    width: 100%;
  }
  
  .editor-container .public-DraftEditor-content {
    font-size: 1.5rem;
    width: 100%;
  }
  
  .save-button {
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #7f388b;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .save-button:hover {
    background-color: #7f388b;
  }
  