/* Reset and Base Styles */
*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Georgia', serif;
    background-color: #fafafa;
    color: #222;
    line-height: 1.8;
    margin: 0;
    padding: 0;
    font-size: 18px;
}

/* Main Container */
article {
    max-width: 100vw;
    margin: 0 auto;
    padding: 40px 20px;
    background: #fff;
    
}

/* Header Styling */
header {
    text-align: center;
    margin-bottom: 2rem;
    padding: 16px;
}

h1 {
    font-size: 3rem;
    line-height: 1.2;
    color: #111;
    font-weight: 400;
    margin-bottom: 1.2rem;
}

.subtitle {
    font-size: 1.25rem;
    color: #555;
    max-width: 600px;
    margin: 0 auto 1.5rem;
    text-align: center;
    line-height: 1.6;
}

/* Author Info */
.author-info {
    margin: 1.5rem 0;
    font-size: 1rem;
    color: #888;
}

.author-info p {
    margin: 0.2rem 0;
}

/* Content Styling */
.prose {
    font-size: 1.25rem;
    line-height: 1.8;
    color: #333;
}

.prose p {
    margin-bottom: 1.5rem;
    font-size: 2rem;
}

.heading-one {
    font-size: 2.5rem;
    font-weight: 400;
    margin: 2rem 0 1rem;
}

.heading-two {
    font-size: 2rem;
    font-weight: 400;
    margin: 1.5rem 0 1rem;
}

.heading-three {
    font-size: 1.75rem;
    font-weight: 400;
    margin: 1.5rem 0 1rem;
}

/* Featured Image */
.featured-image {
    width: 100%;
    height: auto;
    margin: 2rem 0;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Tags */
.tags {
    margin: 1rem 0;
}

.tags span {
    display: inline-block;
    margin-right: 0.5rem;
    font-size: 0.9rem;
    color: #666;
    font-weight: 400;
}

.tags span:before {
    content: '#';
    color: #999;
}

/* Footer */
footer {
    margin-top: 3rem;
    padding-top: 1rem;
    border-top: 1px solid #eaeaea;
    font-size: 0.9rem;
    color: #666;
}

/* Links */
a {
    color: #0056b3;
    text-decoration: none;
    transition: color 0.3s;
}

a:hover {
    color: #004494;
}

/* Image Caption */
img + em {
    display: block;
    text-align: right;
    font-size: 0.9rem;
    color: #999;
    margin-top: -1rem;
    margin-bottom: 2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    h1 {
        font-size: 2.5rem;
    }
    
    .prose {
        font-size: 1.1rem;
    }
    
    article {
        padding: 0 15px;
    }
}
