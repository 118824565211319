/* Base styles */
.max-w-4xl {
    max-width: 56rem;
  }
  
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .p-4 {
    padding: 1rem;
  }
  
  .space-y-12 > * + * {
    margin-top: 3rem;
  }
  
  /* Hero Section */
  .hero-section {
    position: relative;
    background: linear-gradient(to right, #2563eb, #9333ea);
    border-radius: 1rem;
    padding: 2rem;
    color: white;
  }
  
  .hero-overlay {
    position: absolute;
    inset: 0;
    background-color: black;
    opacity: 0.1;
    border-radius: 1rem;
  }
  
  .hero-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  @media (min-width: 768px) {
    .hero-content {
      flex-direction: row;
    }
  }
  
  /* Profile Image */
  .profile-image-container {
    position: relative;
  }
  
  .profile-image {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    border: 4px solid white;
    overflow: hidden;
  }
  
  .profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .online-badge {
    position: absolute;
    bottom: -0.5rem;
    right: 0;
    background-color: #22c55e;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
  }
  
  /* Profile Info */
  .profile-info {
    flex: 1;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .profile-info {
      text-align: left;
    }
  }
  
  .badge-container {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .badge {
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
  }
  
  .badge-yellow {
    background-color: #eab308;
  }
  
  .badge-blue {
    background-color: #1d4ed8;
  }
  
  .badge-outline {
    border: 1px solid white;
    color: white;
  }
  
  .profile-name {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .profile-title {
    font-size: 1.25rem;
    opacity: 0.9;
    margin-bottom: 0.75rem;
  }
  
  .skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 768px) {
    .skills-container {
      justify-content: flex-start;
    }
  }
  
  /* Rating Section */
  .rating-section {
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .rating-section {
      text-align: right;
    }
  }
  
  .rating-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: 768px) {
    .rating-stars {
      justify-content: flex-end;
    }
  }
  
  .star-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #facc15;
  }
  
  .rating-value {
    font-weight: bold;
    font-size: 1.125rem;
  }
  
  .rating-count {
    opacity: 0.9;
  }
  
  /* Pricing Section */
  .pricing {
    text-align: center;
  }
  
  .pricing-label {
    font-size: 0.875rem;
    opacity: 0.9;
  }
  
  .price {
    font-size: 1.875rem;
    font-weight: bold;
  }
  
  .book-button {
    width: 100%;
    background-color: white;
    color: #2563eb;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .book-button:hover {
    background-color: #f3f4f6;
  }
  
  /* Value Proposition Banner */
  .value-prop-banner {
    background: linear-gradient(to right, #f3e8ff, #eff6ff);
    border: 2px solid #dbeafe;
    border-radius: 0.5rem;
    padding: 1.5rem;
  }
  
  .banner-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .banner-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1e40af;
    margin-bottom: 0.5rem;
  }
  
  .banner-subtitle {
    color: #4b5563;
  }
  
  .packages-grid {
    display: grid;
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .packages-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .package-card {
    background-color: white;
    border-radius: 0.75rem;
    padding: 1.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .package-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .package-icon-container {
    padding: 0.5rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
  }
  
  .package-icon {
    width: 2rem;
    height: 2rem;
  }
  
  .package-title {
    font-weight: 600;
    font-size: 1.125rem;
  }
  
  .feature-list {
    list-style: none;
    padding: 0;
    margin: 0;
    space-between: 0.75rem;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.75rem;
  }
  
  .check-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #22c55e;
  }
  
  /* Platform Features */
  .platform-features {
    margin-top: 3rem;
  }
  
  .features-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .features-title {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .features-subtitle {
    color: #4b5563;
  }
  
  .features-grid {
    display: grid;
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .features-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .feature-card {
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    transition: box-shadow 0.3s;
  }
  
  .feature-card:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
  
  .feature-content {
    display: flex;
    gap: 1rem;
  }
  
  .feature-icon-container {
    padding: 0.75rem;
    border-radius: 0.75rem;
    background-color: #f9fafb;
    transition: background-color 0.3s;
  }
  
  .feature-card:hover .feature-icon-container {
    background-color: #eff6ff;
  }
  
  /* Stats Section */
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .stats-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .stat-card {
    text-align: center;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
    transition: box-shadow 0.2s;
  }
  
  .stat-card:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .stat-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .stat-icon-wrapper {
    padding: 0.5rem;
    background-color: #f9fafb;
    border-radius: 0.5rem;
  }
  
  .stat-value {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
  }
  
  .stat-label {
    color: #374151;
    font-weight: 500;
  }
  
  .stat-subtitle {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  /* App Download Section */
  .app-download {
    background: linear-gradient(to right, #2563eb, #9333ea);
    color: white;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
  }
  
  .download-content {
    max-width: 42rem;
    margin: 0 auto;
  }
  
  .download-title {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .download-description {
    font-size: 1.125rem;
    opacity: 0.9;
    margin-bottom: 2rem;
  }
  
  .download-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }
  
  @media (min-width: 768px) {
    .download-buttons {
      flex-direction: row;
    }
  }
  
  .download-button {
    background-color: white;
    color: #2563eb;
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transition: background-color 0.2s;
  }
  
  .download-button:hover {
    background-color: #f3f4f6;
  }
  
  .download-footer {
    margin-top: 1.5rem;
    font-size: 0.875rem;
    opacity: 0.75;
  }
  
  /* Utility Classes */
  .text-center {
    text-align: center;
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
  }
  
  .text-2xl {
    font-size: 1.5rem;
  }
  
  .text-3xl {
    font-size: 1.875rem;
  }
  
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  
  .mb-8 {
    margin-bottom: 2rem;
  }